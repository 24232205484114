<template>
  <v-container fluid>
    <custome-loader v-if="loading"></custome-loader>
    <v-row class="mb-5 text-center my-5">
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" elevation="3" ç v-bind="attrs" v-on="on" outlined>
              <v-card-title class="text-center justify-center">Frais en attente de validation</v-card-title>
              <v-row align="center">
                <v-card-text>
                  <!-- <v-icon size="48" color="EoleGreen">mdi-check-circle</v-icon> -->
                  <h2>{{ getAmountCostsOrderedFilter() }}€</h2>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span>Les frais pour </span>
        </v-tooltip>
      </v-col>

      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" elevation="3" v-bind="attrs" v-on="on" outlined>
              <v-card-title class="text-center justify-center">Frais pour l'année {{ currentYear }}</v-card-title>
              <v-row align="center">
                <v-card-text>
                  <h2>{{ getAmountCostsCurrentYear() }}€</h2>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span> Ce sont le montant des frais total pour l'année en cours ({{ currentYear }}) </span>
        </v-tooltip>
      </v-col>

      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" elevation="3" v-bind="attrs" v-on="on" outlined>
              <v-card-title class="text-center justify-center">Frais à valider</v-card-title>
              <v-row align="center">
                <v-card-text>
                  <h2 class="EoleYellow--text">{{ getCostsState() }}</h2>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span>Ce sont les frais en cours de demande pour l'année en cours ({{ currentYear }}) </span>
        </v-tooltip>
      </v-col>

      <v-col class="d-flex" cols="12" md="3">
        <v-dialog max-width="1300" v-model="dialogCostScale">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              :disabled="isManager"
              class="flex d-flex flex-column rounded-xl"
              elevation="3"
              link
              v-bind="attrs"
              v-on="on"
              outlined
              height="200px"
            >
              <v-card-title class="text-center justify-center">Barème fiscal</v-card-title>
              <v-row align="center">
                <v-card-text>
                  <i class="fa-solid fa-landmark fa-3x EoleBlue--text"></i>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Configuration du barème fiscal pour {{ currentYear }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table dense class="elevation-1">
                        <thead>
                          <tr>
                            <th class="text-left">Puissance du véhicule (CV)</th>
                            <th class="text-left">Tarif par kilomètre (€)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in ratesScale" :key="item.power">
                            <td>{{ item.power === "default" ? "> 7 CV" : `${item.power} CV` }}</td>
                            <td>
                              <v-edit-dialog class="edit-dialog" :return-value="item">
                                {{ item.rate }}€
                                <template v-slot:input>
                                  <div class="edit-dialog">
                                    <v-text-field
                                      v-model="item.rate"
                                      outlined
                                      class="my-5"
                                      dense
                                      label="Nom de la garantie"
                                      single-line
                                    ></v-text-field>
                                  </div>
                                </template>
                              </v-edit-dialog>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>

                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" :disabled="isFinishDisabled" @click="saveScale">Terminer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="mb-n10">
      <v-col cols="12" md="2">
        <v-text-field label="Collaborateur" v-model="userOrdered"> </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field label="Année" v-model="yearOrdered"> </v-text-field>
        <!-- <v-select label="Année(s)" multiple :items="dates" v-model="filter.year" dense></v-select> -->
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field label="Mois de l'année" v-model="monthOrdered"> </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="text-right mt-6">
        <v-btn icon color="EoleBlue">
          <i class="fa-solid fa-folder EoleBlue--text"></i>
        </v-btn>
        <v-dialog max-width="1300" v-model="dialogCostsHistory">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="dialogVisible = true" color="EoleBlue">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Historique des notes de frais</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row class="mb-n10">
                        <v-col cols="12" md="2">
                          <v-text-field label="Collaborateur" v-model="userOrdredHistory"> </v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field label="Année" v-model="yearOrdredHistory"> </v-text-field>
                          <!-- <v-select label="Année(s)" multiple :items="dates" v-model="filter.year" dense></v-select> -->
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field label="Mois de l'année" v-model="monthOrdredHistory"> </v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text>
                      <v-data-table
                        :items="getCostsOrderedHistoryFilter(monthOrdredHistory, userOrdredHistory, yearOrdredHistory)"
                        item-key="id"
                        :headers="headersCosts"
                        hide-default-footer
                        :items-per-page="-1"
                      >
                        <template v-slot:no-data>
                          Aucune note de frais disponible, veuillez changer les paramètres de filtres pour afficher des
                          notes de frais.
                        </template>
                        <template v-slot:[`item.months`]="{ item }"> {{ item.months }} {{ item.year }} </template>
                        <template v-slot:[`item.collaborator`]="{ item }">
                          {{ item.user.firstname }} {{ item.user.lastname }}
                        </template>
                        <template v-slot:[`item.monthPrice`]="{ item }"> {{ item.monthPrice }}€</template>
                        <template v-slot:[`item.state`]="{ item }">
                          <v-chip small v-if="item.state === 0" color="EoleYellow">En attente</v-chip>
                          <v-chip small v-else-if="item.state === 1" color="EoleBlue">A approuver</v-chip>
                          <v-chip small v-else-if="item.state === 2" color="EoleError"
                            ><v-icon left small>mdi-close-thick</v-icon>Refusé</v-chip
                          >
                          <v-chip small v-else color="EoleGreen" outlined
                            ><v-icon left small>mdi-check</v-icon> Validé</v-chip
                          >
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn icon @click="openDialog(item)">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn></template
                        >
                      </v-data-table>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="postNewCompensatoryLeave(newCompensatoryLeaveBalance)"
                        >Terminer</v-btn
                      >
                    </v-card-actions> -->
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :items="getCostsOrderedFilter(monthOrdered, userOrdered, yearOrdered)"
          item-key="id"
          :headers="headersCostsOrdered"
          hide-default-footer
          :items-per-page="-1"
          :loading="loadingCosts"
          loading-text="Chargement des notes de frais"
        >
          <template v-slot:no-data>
            Aucune note de frais disponible, veuillez changer les paramètres de filtres pour afficher des notes de
            frais.
          </template>
          <template v-slot:[`item.months`]="{ item }"> {{ item.months }} {{ item.year }} </template>
          <template v-slot:[`item.collaborator`]="{ item }">
            {{ item.user.firstname }} {{ item.user.lastname }}
          </template>
          <template v-slot:[`item.monthPrice`]="{ item }"> {{ item.monthPrice }}€</template>
          <template v-slot:[`item.state`]="{ item }">
            <v-chip small v-if="item.state === 0" color="EoleYellow">En attente</v-chip>
            <v-chip small v-else-if="item.state === 1" color="EoleBlue">A approuver</v-chip>
            <v-chip small v-else-if="item.state === 2" color="EoleError"
              ><v-icon left small>mdi-close-thick</v-icon>Refusé</v-chip
            >
            <v-chip small v-else color="EoleGreen" outlined><v-icon left small>mdi-check</v-icon> Validé</v-chip>
          </template>
          <!-- <template v-slot:[`item.price`]="{ item }"> {{ item.price }}€</template>
          -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="openDialog(item)">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn></template
          >
        </v-data-table>

        <!-- <v-data-table
          :items="getCostFilter(filter.year, filter.month, filter.idAccount, [1])"
          item-key="id"
          :headers="headersCosts"
          hide-default-footer
        >
          <template v-slot:no-data>
            Aucune note de frais disponible, veuillez changer les paramètres de filtres pour afficher des notes de
            frais.
          </template>
          <template v-slot:[`item.collaborator`]="{ item }">
            {{ item.user.firstname }} {{ item.user.lastname }}
          </template>
          <template v-slot:[`item.price`]="{ item }"> {{ item.price }}€</template>
          <template v-slot:[`item.state`]="{ item }">
            <v-chip small v-if="item.state === 1" color="EoleBlue">A approuver</v-chip>
            <v-chip small v-else-if="item.state === 2" color="EoleError"
              ><v-icon left small>mdi-close-thick</v-icon>Refusé</v-chip
            >
            <v-chip small v-else color="EoleGreen" outlined><v-icon left small>mdi-check</v-icon> Validé</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="openDialog(item)">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn></template
          >
        </v-data-table> -->

        <v-dialog v-model="dialogCheckCost" right transition="slide-x-transition" close-on-content-click width="1500px">
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title class="muller-capitalized EoleBlue--text">
                      Note du {{ selectedCost.date_cost_display }}
                    </v-card-title>
                    <v-card-subtitle> Enregistrée le : {{ selectedCost.date_display }} </v-card-subtitle>
                    <v-card-text class="my-5">
                      <v-data-table
                        v-if="selectedCost.scale === null"
                        :headers="headersSelectedCost"
                        :items="[selectedCost]"
                        hide-default-footer
                      >
                        <template v-slot:[`item.price`]="{ item }"> {{ item.price }}€</template>
                      </v-data-table>
                      <v-data-table v-else :headers="headersCostTransport" :items="[selectedCost]" hide-default-footer>
                        <template v-slot:[`item.designation`]="{ item }"> Transport personnel</template>

                        <template v-slot:[`item.price`]="{ item }"> {{ item.price }}€</template>
                        <template v-slot:[`item.kilometers`]="{ item }"> {{ item.kilometers }}km</template>
                      </v-data-table>
                    </v-card-text>
                    <v-card-text>
                      <v-textarea
                        v-model="selectedCost.cost_manager_comment"
                        label="Commentaire manager"
                        :readonly="selectedCost.state === 2 || selectedCost.state === 3 || selectedCost.state === 0"
                        outlined
                        dense
                      ></v-textarea>
                    </v-card-text>
                    <v-spacer> </v-spacer>
                    <v-card-actions class="justify-center">
                      <v-btn
                        :disabled="selectedCost.state === 2 || selectedCost.state === 3 || selectedCost.state === 0"
                        color="EoleError"
                        class="white--text"
                        @click="
                          putCost(selectedCost, 2, selectedCost.cost_manager_comment, selectedCost.id_cost_manager)
                        "
                        >Refuser</v-btn
                      >
                      <v-btn
                        :disabled="selectedCost.state === 2 || selectedCost.state === 3 || selectedCost.state === 0"
                        color="EoleGreen"
                        class="white--text"
                        @click="
                          putCost(selectedCost, 3, selectedCost.cost_manager_comment, selectedCost.id_cost_manager)
                        "
                        >Valider</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col v-if="selectedCost.scale === null" cols="12" md="3" class="no-padding-top d-flex">
                  <v-dialog
                    v-model="dialogVisualisationDocument"
                    @click:outside="
                      () => {
                        dialogVisualisationDocument = false
                        selectedDocument = {}
                      }
                    "
                    right
                    transition="slide-x-transition"
                    close-on-content-click
                    width="600px"
                  >
                    <v-card>
                      <v-card-title class="text-h5">Visualisation du document</v-card-title>
                      <v-card-text
                        v-if="selectedDocument.type === 'image\/jpeg' || selectedDocument.type === 'image\/png'"
                      >
                        <v-img
                          :src="selectedDocument.url"
                          max-height="600px"
                          max-width="600px"
                          alt="logo"
                          contain
                        ></v-img>
                      </v-card-text>
                      <v-card-text>
                        <iframe
                          v-if="documentPreviewedUrl != null"
                          :src="documentPreviewedUrl"
                          width="100%"
                          height="600px"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn elevation="0" @click="dialogVisualisationDocument = false">Fermer</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-card color="transparent" class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title class="muller-capitalized EoleBlue--text">
                      Justificatifs de la note de frais
                    </v-card-title>
                    <v-list dense class="rounded-xl" style="z-index: 0" v-if="selectedCost.documents.length > 0">
                      <v-list-item
                        class="rounded-xl"
                        v-for="(document, index) in selectedCost.documents"
                        v-bind:key="index"
                        link
                        @click="openVisualisation(document)"
                        target="_blank"
                      >
                        <v-list-item-avatar size="32">
                          <v-avatar color="primary white--text" size="32">
                            <i
                              :class="[
                                'fa-solid ',
                                document.type === 'application/pdf' ? 'fa-file-pdf' : 'fa-file-image',
                                'fa-1x',
                              ]"
                            ></i>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ document.name }}</v-list-item-title>
                          <v-list-item-subtitle>Importé le : {{ document.date }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text v-text="document.size"></v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                      <v-icon size="100" color="EoleBlue">mdi-folder-open-outline</v-icon>
                      <p class="text-h6">Aucun document disponible</p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col v-else cols="12" md="3" class="no-padding-top d-flex">
                  <v-card color="transparent" class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title class="text-no-wrap">Informations </v-card-title>
                    <v-card-text>
                      <v-card class="rounded-xl" flat width="100%">
                        <v-card-text
                          >Motif : <span class="font-weight-bold">{{ selectedCost.reason }}</span>
                        </v-card-text>
                        <v-card-text v-if="selectedCost.client != null"
                          >Nom du client : <span class="font-weight-bold">{{ selectedCost.client }}</span></v-card-text
                        >
                        <v-card-text
                          >Nombre de kilomètres A/R :
                          <span class="font-weight-bold">{{ selectedCost.kilometers }}</span> kms</v-card-text
                        >
                      </v-card>
                    </v-card-text>
                    <v-card-text>
                      <v-timeline dense>
                        <!-- Premier élément à droite -->
                        <v-timeline-item fill-dot>
                          <v-icon slot="icon" color="EoleBlueLighten">mdi-map-marker-outline</v-icon>
                          <div>
                            <h3>Adresse de départ</h3>
                            <p>{{ selectedCost.address_start }}</p>
                          </div>
                        </v-timeline-item>

                        <!-- Second élément à droite -->
                        <v-timeline-item fill-dot>
                          <v-icon slot="icon" color="EoleBlueLighten">mdi-map-marker</v-icon>
                          <div>
                            <h3>Adresse d'arrivée</h3>
                            <p>{{ selectedCost.address_end }}</p>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>

                    <!-- <v-card-text
                      v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 2 && !!newCost.kilometers"
                      class="mt-5"
                    >
                      <v-text-field
                        solo-inverted
                        rounded
                        readonly
                        :value="displayCalculation"
                        label="Calcul basé sur le barème"
                      ></v-text-field>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions
                      class="no-padding-bottom"
                      v-if="parseInt(newCost.idCostCategory) === 1 && newCost.idTransport === 2 && !!newCost.kilometers"
                    >
                      <v-card class="rounded-xl" flat width="100%">
                        <v-card-text>Total : {{ result }}€</v-card-text>
                      </v-card>
                    </v-card-actions> -->
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAction" right transition="slide-x-transition" close-on-content-click width="1300px">
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title class="muller-capitalized EoleBlue--text">
                      {{ selectedItem.user.lastname }} {{ selectedItem.user.firstname }} a de l'argent à se faire
                      rembourser 💸
                    </v-card-title>
                    <v-card-subtitle>
                      Pour le mois de : {{ selectedItem.months }} {{ selectedItem.year }}
                    </v-card-subtitle>
                    <v-card-text class="my-5">
                      <v-data-table :headers="headersCost" :items="selectedItem.month" hide-default-footer>
                        <template v-slot:[`item.price`]="{ item }"> {{ item.price }}€ </template>
                        <template v-slot:[`item.state`]="{ item }">
                          <v-chip small v-if="item.state === 0" color="EoleYellow">En attente</v-chip>
                          <v-chip small v-else-if="item.cost_state === 1" color="EoleBlue">A approuver</v-chip>
                          <v-chip small v-else-if="item.cost_state === 2" color="EoleError"
                            ><v-icon left small>mdi-close-thick</v-icon>Refusé</v-chip
                          >
                          <v-chip small v-else color="EoleGreen" outlined
                            ><v-icon left small>mdi-check</v-icon> Validé</v-chip
                          >
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn icon @click="openCost(item)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                          <!-- <v-btn
                            :disabled="item.state === 2 || item.state === 3 || item.state === 0"
                            v-if="selectedItem.state === 1"
                            icon
                            color="EoleError"
                            @click="generateRejectionReport(item, selectedItem.id_cost_manager)"
                          >
                            <v-icon>mdi-close-thick</v-icon>
                          </v-btn> -->
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <!--
                      <v-data-table v-else :headers="headersCostTransport" :items="[selectedItem]" hide-default-footer>
                        <template v-slot:[`item.price`]="{ item }"> {{ item.price }}€</template>
                        <template v-slot:[`item.vehicleBrand`]="{ item }"> {{ item.data[0].vehicleBrand }}</template>
                        <template v-slot:[`item.vehicleModel`]="{ item }"> {{ item.data[0].vehicleModel }}</template>
                        <template v-slot:[`item.powerTax`]="{ item }"> {{ item.data[0].powerTax }}cv</template>
                        <template v-slot:[`item.scale`]="{ item }"> {{ item.data[0].scale }}€/km</template>
                        <template v-slot:[`item.kilometers`]="{ item }"> {{ item.data[0].kilometers }}km</template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn icon>
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                          <v-btn icon color="EoleError">
                            <v-icon>mdi-close-thick</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card-text>  -->

                    <v-card-text>
                      <v-textarea
                        v-model="selectedItem.manager_comment"
                        label="Commentaire manager"
                        :readonly="selectedItem.state === 2 || selectedItem.state === 3"
                        outlined
                        dense
                      ></v-textarea>
                    </v-card-text>
                    <v-spacer> </v-spacer>
                    <v-card-actions class="text-center justify-center">
                      <v-btn
                        :disabled="selectedItem.state === 2 || selectedItem.state === 3"
                        color="EoleError"
                        class="white--text"
                        @click="putCostManager(selectedItem.id_cost_manager, 2, selectedItem.manager_comment)"
                        >Refuser</v-btn
                      >
                      <v-btn
                        :disabled="selectedItem.state === 2 || selectedItem.state === 3"
                        color="EoleGreen"
                        class="white--text"
                        @click="putCostManager(selectedItem.id_cost_manager, 3, selectedItem.manager_comment)"
                        >Accepter sans justificatif</v-btn
                      >
                      <v-btn
                        :disabled="selectedItem.state === 2 || selectedItem.state === 3"
                        color="EoleGreen"
                        class="white--text"
                        @click="putCostManager(selectedItem.id_cost_manager, 3, selectedItem.manager_comment)"
                        >Accepter avec justificatif</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12" md="3" class="no-padding-top d-flex">
                  <v-card color="transparent" class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title class="muller-capitalized EoleBlue--text">
                      Justificatifs de la note de frais
                    </v-card-title>
                    <v-list dense class="rounded-xl" style="z-index: 0" v-if="selectedItem.documents.length > 0">
                      <v-list-item
                        class="rounded-xl"
                        v-for="(document, index) in selectedItem.documents"
                        v-bind:key="index"
                        link
                        :href="document.url"
                        target="_blank"
                      >
                        <v-list-item-avatar size="32">
                          <v-avatar color="primary white--text" size="32">
                            <i
                              :class="[
                                'fa-solid ',
                                document.type === 'application/pdf' ? 'fa-file-pdf' : 'fa-file-image',
                                'fa-1x',
                              ]"
                            ></i>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ document.name }}</v-list-item-title>
                          <v-list-item-subtitle>Importé le : {{ document.date }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text v-text="document.size"></v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                      <v-icon size="100" color="EoleBlue">mdi-folder-open-outline</v-icon>
                      <p class="text-h6">Aucun document disponible</p>
                    </v-card-text>
                  </v-card>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"

import moment from "moment"
moment.locale("fr")
import FileSaver from "file-saver"
import JSZip from "jszip"
import { saveAs } from "file-saver"

Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})
import imageViewer from "vue-image-viewer"

import * as XLSX from "xlsx"

Vue.use(imageViewer)

import draggable from "vuedraggable"
import { getCookie } from "@/utils/cookies"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    loadingCosts: false,
    documentPreviewedUrl: null,
    vehicle: {
      power: 0,
      rate: 0,
    },
    ratesScale: [],
    isManager: false,
    supervisedMembersIds: [],
    headersScale: [
      {
        text: "Puissance du véhicule (CV)",
        align: "start",
        value: "power",
      },
      {
        text: "Tarif par kilomètre (€)",
        value: "rate",
      },
    ],
    ratesScale: [],
    selectedDocument: {},
    dialogVisualisationDocument: false,
    monthFind: "",
    dialogCheckCost: false,
    costsOrderedHistory: [],
    yearOrdered: "",
    monthOrdered: "",
    userOrdered: "",
    costsOrdered: [],
    selectedCost: "",
    userOrdredHistory: "",
    yearOrdredHistory: "",
    monthOrdredHistory: "",

    dialogCostsHistory: false,
    comments: "",
    startDayLastYear: moment().subtract(1, "year").startOf("year").format("DD/MM/YYYY"),
    endDayLastYear: moment().subtract(1, "year").endOf("year").format("DD/MM/YYYY"),
    startDayYear: moment().startOf("year").format("DD/MM/YYYY"),
    endDayYear: moment().endOf("year").format("DD/MM/YYYY"),
    startDayMonth: moment().startOf("month").format("DD/MM/YYYY"),
    endDayMonth: moment().endOf("month").format("DD/MM/YYYY"),
    dialogAction: false,
    selectedItem: { user: {}, documents: [], data: [] },
    states: [
      {
        id: 1,
        designation: "A approuver",
      },
      {
        id: 2,
        designation: "Refusé",
      },
      {
        id: 3,
        designation: "Validé",
      },
    ],
    currentYear: moment().format("YYYY"),
    lastYear: moment().subtract(1, "year").format("YYYY"),
    filter: {
      year: [],
      month: [],
      idAccount: [],
      state: [],
    },
    costs: [],
    dates: [],
    months: [],
    costAmountOfTheYear: 0.0,
    costAmountOfTheMonth: 0,
    currentMonth: moment().format("MMMM YYYY"),
    showCandidate: false,
    headersHoliday: [
      {
        text: "",
        value: "name",
      },
      {
        text: moment().subtract(1, "year").format("YYYY"),
        value: "lastYear",
      },
      {
        text: moment().format("YYYY"),
        value: "currentYear",
      },
    ],
    nowFormat: moment().format("Do MMMM YYYY"),
    dialogUploadDocument: false,
    searchCity: null,
    cityItems: [],
    stepperAccount: 0,
    expandedCost: [],
    now: moment().format("MMM YYYY"),
    loadingActionCampain: false,
    loadingActionZip: false,
    headersCostTransport: [
      {
        text: "Date de la note de frais",
        align: "start",
        sortable: true,
        value: "date_cost_display",
      },
      {
        text: "Catégorie de frais",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Montant (en €)",
        value: "price",
      },
    ],
    headersSelectedCost: [
      {
        text: "Date de la note de frais",
        align: "start",
        sortable: true,
        value: "date_cost_display",
      },
      {
        text: "Catégorie de frais",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Montant (en €)",
        value: "price",
      },
    ],
    headersCost: [
      {
        text: "Date de la note de frais",
        align: "start",
        sortable: true,
        value: "date_cost_display",
      },
      {
        text: "Catégorie de frais",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Montant (en €)",
        value: "price",
      },
      {
        text: "État",
        value: "state",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    headersCostsOrdered: [
      {
        text: "Mois de la note de frais",
        align: "start",
        sortable: true,
        value: "months",
      },
      {
        text: "Collaborateur",
        sortable: true,
        value: "collaborator",
      },
      // {
      //   text: "Date de la note de frais",
      //   align: "start",
      //   sortable: true,
      //   value: "date_cost_display",
      // },
      {
        text: "Montant (en €)",
        value: "monthPrice",
      },
      {
        text: "État",
        value: "state",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    headersCosts: [
      {
        text: "Mois de la note de frais",
        align: "start",
        sortable: true,
        value: "months",
      },
      {
        text: "Collaborateur",
        sortable: true,
        value: "collaborator",
      },
      // {
      //   text: "Date de la note de frais",
      //   align: "start",
      //   sortable: true,
      //   value: "date_cost_display",
      // },
      {
        text: "Montant (en €)",
        value: "monthPrice",
      },
      {
        text: "État",
        value: "state",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    desactivateData: {
      idAccountLeaveReason: null,
      dateApproval: null,
      dateAgreementApproval: null,
      dateEnd: null,
    },
    test: [
      {
        name: "Complétude des données",
      },
      {
        name: "information personnelle",
      },
      {
        name: "information familliale",
      },
    ],
    reasonLeave: [
      {
        id: 1,
        designation: "Licenciement",
      },
      {
        id: 2,
        designation: "Licenciement économique",
      },
      {
        id: 3,
        designation: "Licenciement pour faute",
      },
      {
        id: 4,
        designation: "Licenciement pour faute lourde",
      },
      {
        id: 5,
        designation: "Rupture conventionnelle",
      },
      {
        id: 6,
        designation: "Démission",
      },
      {
        id: 7,
        designation: "Fin de contrat",
      },
    ],
    expanded: [],
    singleExpand: true,
    page: 1,
    pageCount: 0,
    categoriesHeaders: [
      {
        text: "Catégorie",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Conformité",
        value: "files.length",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    trainingHeaders: [
      {
        text: "Année",
        align: "start",
        sortable: true,
        value: "year",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    categoriesInformationHeaders: [
      {
        text: "",
        align: "start",
        sortable: true,
        value: "name",
      },

      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    fileCategories: [],
    documentPutId: null,
    documentPutName: null,
    dialogUploadPut: false,
    fileUrl: null,
    fileUrlPut: null,
    fileSelected: true,
    document: {
      name: null,
      data: {
        name: null,
        type: null,
      },
      categorie: null,
    },
    documentPut: {
      name: null,
      data: null,
      categorie: null,
      fileId: null,
    },
    dialogUpload: false,
    pdf: null,
    avatarUrlMini: null,
    avatarUrl: null,
    imgError: false,
    editedItemContract: {
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
    },
    dialogAddContractToAccount: false,
    typeEmployments: [],
    typeJobs: [],
    typeContracts: [],
    typeQualifications: [],
    services: [],
    showPhone: false,
    showEmail: false,
    missingData: [],
    showInformations: false,
    showHR: false,
    showInformatical: false,
    showAnnual: false,
    tab: null,
    HRfilesAvailableCount: 0,
    HRfilesNotAvailableCount: 0,
    HRfiles: [
      {
        id: 0,
        name: "Pièce d'identité en cours de validité",
        available: false,
        loading: false,
      },
      {
        id: 1,
        name: "Relevé d'identité bancaire",
        available: false,
        loading: false,
      },
      {
        id: 2,
        name: "Attestation de sécurité sociale",
        available: true,
        loading: false,
      },
      {
        id: 3,
        name: "Décision unilatérale de l'employeur santé",
        available: false,
        loading: false,
      },
      {
        id: 4,
        name: "Décision unilatérale de l'employeur prévoyance",
        available: false,
        loading: false,
      },
      {
        id: 5,
        name: "Notice d'information santé",
        available: false,
        loading: false,
      },
      {
        id: 6,
        name: "Notice d'information prévoyance",
        available: false,
        loading: false,
      },
      {
        id: 7,
        name: "Contrat de travail",
        available: true,
        loading: false,
      },

      {
        id: 8,
        name: "Attestation d'honorabilité",
        available: false,
        loading: false,
      },
      {
        id: 9,
        name: "Formations",
        available: false,
        loading: false,
      },
      {
        id: 10,
        name: "Charte informatique",
        available: false,
        loading: false,
      },
    ],
    imagesViews: [],
    itemsInformatical: [
      {
        id: 0,
        name: "Souris",
        quantity: 4,
      },
      {
        id: 1,
        name: "Ordinateur fixe",
        quantity: 1,
      },
      {
        id: 2,
        name: "Ordinateur portable",
        quantity: 1,
      },
      {
        id: 3,
        name: "Écran",
        quantity: 1,
      },
      {
        id: 4,
        name: "Imprimante",
        quantity: 1,
      },
      {
        id: 5,
        name: "Téléphone fixe",
        quantity: 1,
      },
      {
        id: 6,
        name: "Téléphone portable",
        quantity: 1,
      },
      {
        id: 7,
        name: "Casque",
        quantity: 1,
      },
      {
        id: 8,
        name: "Clé USB",
        quantity: 1,
      },
    ],
    itemInformaticalAttribute: [],
    usersDisabledCount: 0,
    usersEnabledCount: 0,
    usersUnavailableCount: 0,
    usersUnavailable: [],
    score: 0,
    fileCount: 3,
    fileMax: 4,
    saveFile: false,
    fab: false,
    valueHRfiles: 75,
    agencyName: null,
    load: false,
    ipError: false,
    ip: "",
    ipLocation: "",
    /* test purpose*/
    active: [],
    avatar: null,
    open: [],
    users: [],
    usersDisabled: [],
    fallback:
      "https://images.unsplash.com/photo-1560391243-e6431f0fa114?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80",
    /* end test purpose */
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Ce champ est requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 12 || "Minimum 12 caractères",
    },
    emailRules: [
      v => !!v || "Une adresse électronique est requise",
      v => /.+@.+\..+/.test(v) || "L'adresse électronique doit être valide : example@gmail.com",
    ],
    ipInfo: [],
    snackbar: {
      text: "",
      color: "",
      icon: "",
      enable: false,
    },
    waitTimeout: false,
    date: null,
    time: null,
    page: 1,
    pageCount: 0,
    loading: false,
    fetch: false,
    dialog: false,
    dialogDelete: false,
    dialogIp: false,
    headers: [
      {
        text: "",
        value: "phoneNumber",
        sortable: false,
      },
      {
        text: "COLLABORATEUR",
        align: "start",
        sortable: false,
        value: "completeName",
      },
      {
        text: "QUALITÉ",
        value: "statut",
      },
      {
        text: "EMAIL",
        value: "email",
      },
      {
        text: "DERNIÈRE CONNEXION",
        value: "lastConnection",
      },
      {
        text: "ADRESSE IP",
        value: "lastIPConnected",
      },
      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
      },
    ],
    selectedCost: {
      id: null,
      user: {},
      date: null,
      date_display: null,
      state: null,
      manager_comment: null,
      documents: [],
      data: [],
    },
    editedIndex: -1,
    editedItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "collaborateur",
      email: "",
      phoneNumber: "",
      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
      familyMembers: [],
    },
    defaultItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "",
      email: "",
      phoneNumber: "",
      serviceChoose: "",
      idTypeContract: null,
      idTypeEmployment: null,
      idTypeJob: null,
      idTypeQualification: null,
    },
    loadingFiles: false,
    loadedFiles: false,
    loadingUserData: false,
    familyHeaders: [
      {
        text: "Lien de parenté",
        value: "designation",
      },
      {
        text: "Nom",
        value: "lastname",
      },
      {
        text: "Prénom",
        value: "firstname",
      },
    ],
    dialogUploadFile: false,
    fileUrl: null,
  }),

  computed: {
    isFinishDisabled() {
      // Retourne `true` si au moins un `item.rate` est égal à 0
      return this.ratesScale.some(item => item.rate == 0)
    },
    monthFinded() {
      return this.months.find(month => month.id === this.monthFind)
    },
    filteredCostItems() {
      // Filtrer les produits en fonction du texte saisi dans v-text-field
      const searchText = this.selectedCost.toLowerCase()
      let filter = this.costsOrderedHistory.filter(item => {
        return item.user.complete_name.toLowerCase().includes(searchText)
      })

      return filter
    },
    allUsersSelected() {
      return this.users.length === this.filter.idAccount.length
    },
    icon() {
      if (this.allUsersSelected) return "mdi-close-box"
      return "mdi-checkbox-blank-outline"
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau collaborateur"
        : "Modification pour " + this.editedItem.lastname + " " + this.editedItem.firstname
    },
    items() {
      return [
        {
          id: 1,
          name: `Mon équipe en activité (${this.users.length})`,
          children: this.users,
          open: false,
        },
        {
          id: 2,
          name: `Collaborateurs ayant quitté l'entreprise (${this.usersDisabled.length})`,
          children: this.usersDisabled,
          open: false,
        },
      ]
    },
    selected() {
      this.imagesViews = []
      if (!this.active.length) return undefined

      const id = this.active[0]

      //get firstname of user selected

      if (!this.loadingFiles) {
        const user = this.users.find(user => user.id === id)
        const userDisable = this.usersDisabled.find(user => user.id === id)
        if (user) {
          this.getImg(
            this.users.find(user => user.id === id).lastname,
            this.users.find(user => user.id === id).firstname,
          )
        } else if (userDisable) {
          this.getImg(
            this.usersDisabled.find(user => user.id === id).lastname,
            this.usersDisabled.find(user => user.id === id).firstname,
          )
        } else {
          // Handle the case where the user with the specified 'id' was not found
          console.error(`User with id ${id} not found.`)
        }

        this.$imageViewer.images(this.imagesViews)
      }
      return this.users.find(user => user.id === id) || this.usersDisabled.find(user => user.id === id)
    },
  },

  async created() {
    this.isManager = localStorage.getItem("grade") === "manager"
    this.generateYears()
    this.generateMonth()
    this.initialize()
    this.fetchManage()
    this.fetchCosts()
    this.fetchRatesScale()
  },
  components: {
    appbar,
    Loading,
    CustomeLoader,
    draggable,
  },

  methods: {
    async previewFile(path) {
      const idAccount = this.selectedCost.id_account
      let url
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            url: path,
          }),
        })
        const data = await response.blob()
        url = URL.createObjectURL(data)
      } catch (error) {
        console.error(error)
      }

      return url
    },
    async fetchRatesScale() {
      try {
        const response = await fetch(`${config.apiUri}/scales`, {
          headers: {
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()
        this.ratesScale = data

        this.ratesScale.forEach(item => {
          item.power != "default" ? (item.power = parseInt(item.power)) : (item.power = item.power)
          item.rate = parseFloat(item.rate)
        })
      } catch (error) {
        console.error(error)
      }
    },
    verifyAllScale() {
      this.ratesScale.forEach(item => {
        if (item.rate === 0) {
          return false
        }
      })

      return true
    },
    saveScale() {
      const postData = {
        scales: this.ratesScale,
      }
      fetch(`${config.apiUri}/scales`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
      }).then(response => {
        if (response.ok) {
          this.$toast.success("Vous avez modifié le barème", {
            position: "bottom-right",
          })
        } else {
          this.$toast.error("Erreur", {
            position: "bottom-right",
          })
        }
      })
    },
    getAmountCostsCurrentYear() {
      let total = 0.0
      let costs = this.getCostsOrderedHistoryFilter("", "", this.currentYear)

      costs.forEach(item => {
        if (item.state == 3) {
          console.log(item)
          total += parseFloat(item.monthPrice)
        }
      })
      return parseFloat(total).toFixed(2)
    },
    async openVisualisation(document) {
      this.selectedDocument = document
      console.log("CURENT DOC", document)
      //if document doesnt have path value
      if (!document.path) {
        this.documentPreviewedUrl = document.url
        this.dialogVisualisationDocument = true
      } else {
        await this.previewFile(document.url)
          .then(url => {
            this.documentPreviewedUrl = url
          })
          .finally(() => {
            this.dialogVisualisationDocument = true
          })
      }
    },
    getAmountCostsOrderedFilter() {
      let total = 0
      let costs = this.getCostsOrderedFilter(this.monthOrdered, this.userOrdered, this.yearOrdered)

      costs.forEach(item => {
        total += parseFloat(item.monthPrice)
      })
      return parseFloat(total).toFixed(2)
    },
    openCost(item) {
      this.dialogCheckCost = true
      this.selectedCost = item

      this.fetchAccountVehicle(item.id_account)
    },
    async fetchAccountVehicle(idAccount) {
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/vehicle`)
        const data = await response.json()
        this.vehicle = data.vehicle
      } catch (error) {
        console.error(error)
      }
    },
    generateRejectionReport(item, idCostManager) {
      this.selectedItem.manager_comment += `\rVeuillez corriger les erreurs suivantes sur la note du ${item.date_cost_display} : \n`
      this.putCost(item.id, 2, this.selectedItem.manager_comment, idCostManager)
    },

    async putCost(selectedCost, answer, comment, idCostManager = null) {
      try {
        const postData = {
          comment: comment,
          action: answer === 2 ? "reject" : "confirm",
          idCostManager: idCostManager,
          idAccountAction: this.$store.state.user.id,
        }

        const response = await fetch(`${config.apiUri}/costs/${selectedCost.id}`, {
          method: "PUT",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (response.ok) {
          this.$toast.success("Vous avez modifié la demande de frais", {
            position: "bottom-right",
          })
          this.dialogCheckCost = false

          this.fetchManage()
          await this.fetchCosts().finally(() => {
            const cost = this.costsOrdered.find(cost => cost.id_cost_manager === idCostManager)
            console.log("ordered", this.costsOrdered)
            console.info("COST", cost, idCostManager)

            this.openDialog(cost)
          })
        } else {
          this.$toast.error("Erreur", {
            position: "bottom-right",
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async putCostManager(idCostManager, answer, comment) {
      try {
        fetch(`${config.apiUri}/cost-managers/${idCostManager}`, {
          method: "PUT",
          body: JSON.stringify({
            action: answer === 2 ? "reject" : "confirm",
            comment: comment,
            idAccountAction: this.$store.state.user.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de frais", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogAction = false
            this.fetchManage()
            this.fetchCosts()
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },

    openDialog(item) {
      // Méthode pour ouvrir le v-dialog pour un élément donné
      this.selectedItem = item
      this.dialogAction = true
    },
    getCostsState() {
      let total = 0

      this.costsOrdered.forEach(item => {
        if (item.state === 1) {
          total += 1
        }
      })
      return total
    },
    getCostsAmountYearFiltered() {
      let currentYear = parseInt(moment().format("YYYY"))
      this.costsOrdered.forEach(item => {
        if (item.year === currentYear) {
          item.month.forEach(month => {
            this.costAmountOfTheYear += parseFloat(month.price)
          })
        }
      })

      this.costsOrderedHistory.forEach(item => {
        if (item.year === currentYear) {
          item.month.forEach(month => {
            this.costAmountOfTheYear += parseFloat(month.price)
          })
        }
      })
    },
    getCostsAmountFiltered() {
      let currentIdMonth = parseInt(moment().format("MM"))
      let currentYear = parseInt(moment().format("YYYY"))
      this.costsOrdered.forEach(item => {
        item.month.forEach(month => {
          if (month.month === currentIdMonth && item.year === currentYear) {
            this.costAmountOfTheMonth += month.price
          }
        })
      })

      this.costsOrderedHistory.forEach(item => {
        item.month.forEach(month => {
          if (month.month === currentIdMonth && item.year === currentYear) {
            this.costAmountOfTheMonth += month.price
          }
        })
      })
    },

    getCostsOrderedFilter(monthOrdered = "", userOrdered = "", yearOrdered = "") {
      this.costAmountOfTheMonth = 0
      return this.costsOrdered.filter(cost => {
        const isMonthValid = cost.months.toLowerCase().includes(monthOrdered.toLowerCase())
        const isUserValid = cost.user.complete_name.toLowerCase().includes(userOrdered.toLowerCase())
        const isYearValid = cost.year.toString().toLowerCase().includes(yearOrdered.toLowerCase())
        this.monthFind = cost.months.toLowerCase()
        // const isYearValid = cost.months.includes(yearOrdered)
        return isMonthValid && isUserValid && isYearValid
      })
    },

    getCostsOrderedHistoryFilter(monthOrdered = "", userOrdered = "", yearOrdered = "") {
      return this.costsOrderedHistory.filter(cost => {
        const isMonthValid = cost.months.toLowerCase().includes(monthOrdered.toLowerCase())
        const isUserValid = cost.user.complete_name.toLowerCase().includes(userOrdered.toLowerCase())
        const isYearValid = cost.year.toString().toLowerCase().includes(yearOrdered.toLowerCase())
        // const isYearValid = cost.months.includes(yearOrdered)
        return isMonthValid && isUserValid && isYearValid
      })
    },
    async fetchManage() {
      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      let isManager = this.$store.state.user.grade === "manager"
      console.warn("isManager", isManager)
      let idAccount = this.$store.state.user.id
      this.supervisedMembersIds = [] // Assurez-vous d'avoir cette ligne pour réinitialiser à chaque appel

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/manage`, {
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        this.newDemandCount = data.account.count_total_holidays
        this.newCostCount = data.account.count_total_costs

        if (isManager) {
          if (data.account && Array.isArray(data.account.services)) {
            this.supervisedAccount = data.account.services.map(service => {
              service.collaborators.forEach(collaborator => {
                this.supervisedMembersIds.push(collaborator.id) // Collecter les IDs
              })
              return {
                id: service.id,
                name: service.name,
                members: service.collaborators,
              }
            })
          } else {
            console.error("Structure de données inattendue", data)
          }
        }

        console.log(this.supervisedMembersIds)
      } catch (error) {
        console.error("Error:", error)
      }
    },
    async fetchCostsAvant() {
      this.costs = []
      this.users = []
      this.costsOrdered = []
      this.costsOrderedHistory = []
      this.costAmountOfTheMonth = 0
      this.costAmountOfTheYear = 0.0
      let isManager = localStorage.getItem("grade") === "manager"

      try {
        let headers = new Headers()
        let idAgency = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(`${config.apiUri}/agencies/${idAgency}/costs`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          data.costs.forEach(user => {
            console.log(data.costs)
            user.costs.forEach(yearObj => {
              yearObj.months.forEach(monthObj => {
                let totalMonth = 0
                monthObj.costs.forEach(item => {
                  // Traitement de chaque item de coût
                  item.id = item.id
                  item.designation = item.designation_category
                  item.price = parseFloat(item.price)
                  item.documents = JSON.parse(item.documents)
                  item.id_account = item.id_account
                  item.id_account_cost_category = item.id_account_cost_category
                  item.data = item.data != null ? JSON.parse(item.data) : []
                  item.date = item.date
                  item.date_display = moment(item.date).format("DD/MM/YYYY")
                  item.date_cost = item.date_cost
                  item.date_cost_display = moment(item.date_cost).format("DD/MM/YYYY")
                  item.month = parseInt(moment(item.date_cost).format("MM"))
                  item.month_display = moment(item.date_cost).format("MMMM")
                  item.year = parseInt(moment(item.date_cost).format("YYYY"))
                  item.state = parseInt(item.cost_state)
                  item.manager_comment = item.manager_comment
                  item.cost_manager_comment = item.cost_manager_comment

                  totalMonth += item.price
                })
                // Ajout des informations de l'utilisateur et du mois dans costsOrdered

                if (parseInt(monthObj.state) === 1) {
                  this.costsOrdered.push({
                    id_cost_manager: parseInt(monthObj.id_cost_manager),
                    manager_comment: monthObj.manager_comment,
                    year: yearObj.year,
                    idMonth: parseInt(
                      moment(monthObj.costs.length > 0 ? monthObj.costs[0].date_cost : "").format("MM"),
                    ),
                    month: monthObj.costs,
                    user: {
                      id: user.id,
                      firstname: user.firstname,
                      lastname: user.lastname,
                      complete_name: `${user.firstname} ${user.lastname}`,
                    },
                    monthPrice: totalMonth,
                    state: parseInt(monthObj.state),
                    months: moment(monthObj.costs.length > 0 ? monthObj.costs[0].date_cost : "").format("MMMM"),
                  })
                } else if (parseInt(monthObj.state) === 2 || parseInt(monthObj.state) === 3) {
                  this.costsOrderedHistory.push({
                    id_cost_manager: parseInt(monthObj.id_cost_manager),
                    manager_comment: monthObj.manager_comment,

                    year: yearObj.year,
                    idMonth: parseInt(
                      moment(monthObj.costs.length > 0 ? monthObj.costs[0].date_cost : "").format("MM"),
                    ),
                    month: monthObj.costs,
                    user: {
                      id: user.id,
                      firstname: user.firstname,
                      lastname: user.lastname,
                      complete_name: `${user.firstname} ${user.lastname}`,
                    },
                    monthPrice: totalMonth,
                    state: parseInt(monthObj.state),
                    months: moment(monthObj.costs.length > 0 ? monthObj.costs[0].date_cost : "").format("MMMM"),
                  })
                }
              })
            })
          })

          this.getCostsAmountFiltered()
          this.getCostsAmountYearFiltered()
        }
      } catch (e) {
        console.log(e)
      }
    },

    async fetchCosts() {
      this.loadingCosts = true
      this.costs = []
      this.users = []
      this.costsOrdered = []
      this.costsOrderedHistory = []
      this.costAmountOfTheMonth = 0
      this.costAmountOfTheYear = 0.0

      let isManager = this.$store.state.user.grade === "manager" // Vérifie si l'utilisateur est manager

      try {
        let headers = new Headers()
        let idAgency = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${idAgency}/costs`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          // Filtrage initial pour les managers
          let filteredUsers = isManager
            ? data.costs.filter(user => this.supervisedMembersIds.includes(user.id))
            : data.costs

          console.log("filteredUsers", filteredUsers)

          filteredUsers.forEach(user => {
            this.users.push(user) // Ceci ajoutera seulement les utilisateurs filtrés si isManager est true
            user.costs.forEach(yearObj => {
              yearObj.months.forEach(monthObj => {
                let totalMonth = 0
                monthObj.costs.forEach(item => {
                  // Traitement de chaque item de coût
                  item.designation = item.designation_category
                  item.price = parseFloat(item.price)
                  item.documents = JSON.parse(item.documents)
                  item.data = item.data != null ? JSON.parse(item.data) : []
                  item.date_display = moment(item.date).format("DD/MM/YYYY")
                  item.date_cost_display = moment(item.date_cost).format("DD/MM/YYYY")
                  item.month_display = moment(item.date_cost).format("MMMM")
                  item.year = parseInt(moment(item.date_cost).format("YYYY"))

                  totalMonth += parseFloat(item.price)
                })

                // Conditions pour ajouter dans costsOrdered ou costsOrderedHistory
                let conditionToAdd = parseInt(monthObj.state) === 1
                let targetArray = conditionToAdd ? this.costsOrdered : this.costsOrderedHistory

                if (conditionToAdd || parseInt(monthObj.state) === 2 || parseInt(monthObj.state) === 3) {
                  targetArray.push({
                    // Ajout des coûts filtrés avec les informations utilisateur
                    year: yearObj.year,
                    id_cost_manager: parseInt(monthObj.id_cost_manager),
                    idMonth: monthObj.costs[0] ? parseInt(moment(monthObj.costs[0].date_cost).format("MM")) : 0,
                    month: monthObj.costs,
                    user: {
                      id: user.id,
                      firstname: user.firstname,
                      lastname: user.lastname,
                      complete_name: `${user.firstname} ${user.lastname}`,
                    },
                    monthPrice: parseFloat(totalMonth).toFixed(2),
                    state: parseInt(monthObj.state),
                    months: monthObj.costs[0] ? moment(monthObj.costs[0].date_cost).format("MMMM") : "",
                  })
                }
              })
            })
          })

          this.getCostsAmountFiltered()
          this.getCostsAmountYearFiltered()

          this.loadingCosts = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    generateYears() {
      for (let i = 2022; i <= moment().format("YYYY"); i++) {
        this.dates.push(i)
      }
    },
    generateMonth() {
      for (let i = 1; i <= 12; i++) {
        this.months.push({
          id: i,
          value: i,
          name: moment(i, "MM").format("MMMM"),
        })
      }
    },
    // orderCostsByMonth() {
    //   this.costsOrdered = [
    //     {
    //       month: idMonth,
    //       costs:
    //     }
    //   ]
    // },
    removeUrlPrefix(url) {
      // replace the ged url to : C:\xampp\htdocs\documents\

      // after all add http://api.eole.local/api/php/pages/download.php?path= before the url
      return url.replace(`${config.ged}`, `${config.apiUri}/php/pages/download.php?path=C:/xampp/htdocs/documents/`)
    },
    // async generateAndDownloadZip() {
    //   this.loadingActionZip = true
    //   // Créez une instance JSZip
    //   const zip = new JSZip()

    //   // Liste de liens vers les fichiers PDF avec les répertoires souhaités
    //   let pdfLinks = [
    //     // Ajoutez ici d'autres liens vers les PDF avec les répertoires souhaités
    //   ]

    //   this.users.forEach(user => {
    //     let csvData = []

    //     // Générez un fichier XLSX en mémoire
    //     let wb = XLSX.utils.book_new()

    //     if (user.costs.length > 0) {
    //       let total = 0
    //       user.costs.forEach(cost => {
    //         csvData.push([cost.designation, cost.price])
    //         cost.documents.forEach(document => {
    //           pdfLinks.push({
    //             // replace all "/" to "\"
    //             url: this.removeUrlPrefix(document.url),
    //             pathOnServer: this.removeUrlPrefix(document.url),
    //             folder: `${user.firstname} ${user.lastname}`,
    //           })
    //         })
    //         total += parseInt(cost.price)
    //       })
    //       csvData.push(["Total", total])
    //       let ws = XLSX.utils.aoa_to_sheet(csvData)
    //       XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
    //       let xlsxBuffer = XLSX.write(wb, {
    //         bookType: "xlsx",
    //         type: "array",
    //       })
    //       zip.file(
    //         `${user.firstname} ${user.lastname}/compte_frais-${user.firstname} ${user.lastname}.xlsx`,
    //         xlsxBuffer,
    //       )
    //     }
    //   })

    //   // Ajoutez le fichier CSV à l'archive ZIP

    //   // Fonction pour télécharger un fichier PDF avec fetch et l'ajouter à l'archive ZIP dans le répertoire spécifié
    //   const downloadAndAddToZip = async ({ url, folder }) => {
    //     const response = await fetch(url)
    //     const pdfBlob = await response.blob()
    //     const fileName = url.split("/").pop()
    //     zip.file(`${folder}/${fileName}`, pdfBlob) // Crée un répertoire et ajoute le fichier dedans
    //     // ajouter un fichier dans ce repertoire
    //   }

    //   // Téléchargez chaque fichier PDF et ajoutez-le à l'archive avec le répertoire correspondant
    //   await Promise.all(pdfLinks.map(downloadAndAddToZip))

    //   // Générez l'archive au format blob
    //   const zipBlob = await zip.generateAsync({
    //     type: "blob",
    //   })

    //   // Utilisez file-saver pour télécharger l'archive
    //   saveAs(zipBlob, "documents.zip")
    //   this.loadingActionZip = false
    // },
    async generateAndDownloadZip() {
      this.loadingActionZip = true
      // Créez une instance JSZip
      const zip = new JSZip()

      // Liste de liens vers les fichiers PDF avec les répertoires souhaités
      let pdfLinks = [
        // Ajoutez ici d'autres liens vers les PDF avec les répertoires souhaités
      ]

      this.users.forEach(user => {
        let csvData = []

        // Générez un fichier XLSX en mémoire
        let wb = XLSX.utils.book_new()

        if (user.costs.length > 0) {
          let total = 0
          user.costs.forEach(cost => {
            csvData.push([cost.designation, cost.price])
            cost.documents.forEach(document => {
              pdfLinks.push({
                // replace all "/" to "\"
                url: this.removeUrlPrefix(document.url),
                pathOnServer: this.removeUrlPrefix(document.url),
                folder: `${user.firstname} ${user.lastname}`,
              })
            })
            total += parseInt(cost.price)
          })
          csvData.push(["Total", total])
          let ws = XLSX.utils.aoa_to_sheet(csvData)
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
          let xlsxBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          })
          zip.file(
            `${user.firstname} ${user.lastname}/compte_frais-${user.firstname} ${user.lastname}.xlsx`,
            xlsxBuffer,
          )
        }
      })

      // Ajoutez le fichier CSV à l'archive ZIP

      // Fonction pour télécharger un fichier PDF avec fetch et l'ajouter à l'archive ZIP dans le répertoire spécifié
      const downloadAndAddToZip = async ({ url, folder }) => {
        const response = await fetch(url)
        const pdfBlob = await response.blob()
        const fileName = url.split("/").pop()
        zip.file(`${folder}/${fileName}`, pdfBlob) // Crée un répertoire et ajoute le fichier dedans
        // ajouter un fichier dans ce repertoire
      }

      // Téléchargez chaque fichier PDF et ajoutez-le à l'archive avec le répertoire correspondant
      await Promise.all(pdfLinks.map(downloadAndAddToZip))

      // Générez l'archive au format blob
      const zipBlob = await zip.generateAsync({
        type: "blob",
      })

      // Utilisez file-saver pour télécharger l'archive
      saveAs(zipBlob, "documents.zip")
      this.loadingActionZip = false
    },

    async fakeAction() {
      this.loadingActionCampain = true

      for (const user of this.users) {
        await new Promise(resolve => setTimeout(resolve, 1100)) // Pause de 4,5 secondes

        try {
          await emailjs.send("service_5p49t7p", "template_p1eamen", {
            to_name: user.firstname,
            to_email: user.email,
          })
        } catch (e) {
          console.log(e)
        }
      }
      this.loadingActionCampain = false
    },
    // async fetchCostByUserId() {
    //   this.users.forEach(async user => {
    //     try {
    //       const response = await fetch(`${config.apiUri}/accounts/${user.id}/costs`, {
    //         method: "GET",
    //       })
    //       const data = await response.json()

    //       user.costs = data.costs
    //     } catch (e) {
    //       console.log(e)
    //     }
    //   })
    // },
    imageView(documentUrl) {
      let index = this.imagesViews.findIndex(image => image.url === `http://${documentUrl}`)

      this.$imageViewer.index(index)
      this.$imageViewer.show()
    },
    dialogUploadPutClick(fileId, fileName) {
      this.dialogUploadPut = true
      this.documentPut.fileId = fileId
      this.documentPut.name = fileName
    },
    // async downloadFile(path) {
    //   try {
    //     const response = await fetch(`http://${path}`, { method: "GET", mode: "no-cors" })
    //     const fileBlob = await response.blob()
    //     const file = new File([fileBlob], "nomDuFichier.png", { type: "image/png" })
    //     FileSaver.saveAs(file)
    //   } catch (err) {
    //     console.error(err)
    //   }
    // },
    async downloadFile(path, documentName, extension) {
      let blobConstruct = null
      let headers = new Headers()

      headers.append("Content-Type", "image/jpeg")
      headers.append("Accept", "image/jpeg")
      headers.append("Origin", "*")

      try {
        const response = await fetch(`${config.apiUri}/php/pages/download.php?path=${path}`, {
          method: "GET",
          headers: headers,
        })

        blobConstruct = await response.blob()

        FileSaver.saveAs(blobConstruct, `${documentName}.${extension}`)
      } catch (e) {
        console.log(e)
      }
    },

    replaceSpacesWithUnderscores() {
      this.document.name = this.document.name.replace(/ /g, "_")
    },
    replaceSpacesWithUnderscoresPut() {
      this.documentPut.name = this.documentPut.name.replace(/ /g, "_")
    },
    renderPdf(document) {
      pdfjs.getDocument(document).then(pdf => {
        pdf.getPage(1).then(page => {
          const scale = 0.5
          const viewport = page.getViewport({
            scale: scale,
          })
          const canvas = this.$refs.canvas
          const context = canvas.getContext("2d")
          canvas.height = viewport.height
          canvas.width = viewport.width
          page.render({
            canvasContext: context,
            viewport: viewport,
          })
        })
      })
    },

    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    onDropPut(e, fileId, documentName) {
      e.preventDefault()
      this.documentPut.data = e.dataTransfer.files[0]

      if (this.documentPut.fileId === null && this.documentPut.name === null) {
        this.documentPut.fileId = fileId
        this.documentPut.name = documentName.replace(/ /g, "_")
      }

      if (this.documentPut.fileId != null && this.documentPut.name != null) {
        this.documentPut.name = this.documentPut.name.replace(/ /g, "_")

        let realSize = this.formatBytes(this.documentPut.data.size)

        if (this.documentPut.data.size > 1000000) {
          this.$toast.error(
            `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
            {
              position: "bottom-right",
              timeout: 3000,
            },
          )
          this.documentPut = {
            name: null,
            data: null,
            categorie: null,
            fileId: null,
          }
        } else {
          switch (this.documentPut.data.type) {
            case "application/pdf":
            case "image/png":
            case "image/jpeg":
              this.fileSelected = true
              this.fileUrlPut = URL.createObjectURL(this.documentPut.data)
              this.dialogUploadPut = true

              break
            default:
              this.documentPut = {
                name: null,
                data: null,
                categorie: null,
                fileId: null,
              }
              this.fileSelected = false
              this.$toast.error(
                `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
                {
                  position: "bottom-right",
                  timeout: 3000,
                },
              )
          }
        }
      }
    },

    onDrop(e) {
      e.preventDefault()
      this.document.data = e.dataTransfer.files[0]
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
        }
      } else {
        switch (this.document.data.type) {
          case "application/pdf":
            this.fileSelected = true
            // this.renderPdf(this.document.data)

            // view pdf in iframe

            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          case "image/png":
          case "image/jpeg":
            this.fileSelected = true
            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },

    async uploadPutFile(username, id, fileId, categorieId) {
      const formData = new FormData()
      formData.append("document", this.documentPut.data)
      formData.append("userId", id)
      formData.append("documentName", this.documentPut.name)
      formData.append("username", username.toLowerCase().replace(" ", ""))
      formData.append("fileId", fileId)
      formData.append("method", "PUT")
      formData.append("categorieId", categorieId)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "account")

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.initialize()
          this.fetchUsers()
          this.dialogUploadPut = false
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },

    onImgError() {
      this.imgError = true
    },

    async uploadPDF(username, id, categorieId) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("userId", id)
      formData.append("documentName", this.document.name)
      formData.append("username", username.toLowerCase().replace(" ", ""))
      formData.append("categorieId", categorieId)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "account")

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.initialize()
          this.fetchUsers()
          this.dialogUpload = false

          this.document = {
            name: "",
            data: null,
            categorie: null,
          }
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    onImgError() {
      this.imgError = true
    },

    // get image in folder asset for avatar and display error if not found
    getImg(lastname, firstname) {
      this.imgError = false

      try {
        let name = `${lastname.toLowerCase()}-${firstname.toLowerCase()}`
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`

        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.avatarUrl = img.src
        }
        img.onerror = () => {
          this.imgError = true
          this.avatarUrl = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.avatarUrl == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.imgError = true
        this.avatarUrl == null
      }
    },
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        console.error("Error fetching avatar:", error)
        return null // Return null in case of any errors
      }
    },
    insertFile(documentId) {
      this.HRfiles.forEach(file => {
        if (file.id === documentId) {
          file.loading = true
          setTimeout(() => {
            file.loading = false
            file.available = !file.available
            this.HRfilesAvailableCount = this.HRfiles.filter(file => file.available === true).length
          }, 3000)
        }
      })
    },

    getCurrentDate() {
      var today = new Date()
      var date = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
      var time = today.getHours() + ":" + today.getMinutes()
      this.date = date
      this.time = time
    },

    // async fetchUsers() {
    //   this.usersUnavailableCount = 0
    //   this.usersEnabledCount = 0
    //   this.usersDisabledCount = 0
    //   this.loading = true
    //   let month = moment().format("MM")
    //   let year = moment().format("YYYY")
    //   console.log(month)

    //   const now = moment().format("YYYY-MM-DD")

    //   await pause(1500)

    //   try {
    //     let headers = new Headers()
    //     let userId = localStorage.getItem("user_id")

    //     headers.append("Content-Type", "application/json")
    //     headers.append("Accept", "application/json")
    //     headers.append("Origin", "*")

    //     let response
    //     if (localStorage.getItem("master_account_linked") != null) {
    //       let masterAccountId = localStorage.getItem("master_account_linked")
    //       response = await fetch(`${config.apiUri}/users/${masterAccountId}/account`, {
    //         mode: "cors",
    //         method: "GET",
    //         headers: headers,
    //       })
    //     } else {
    //       response = await fetch(`${config.apiUri}/users/${userId}/account`, {
    //         mode: "cors",
    //         method: "GET",
    //         headers: headers,
    //       })
    //     }

    //     const data = await response.json()

    //     this.getCurrentDate()
    //     localStorage.setItem("update_time", this.date + " " + this.time)

    //     // Créez un tableau de promesses pour récupérer les avatars de manière asynchrone
    //     const avatarPromises = data.map(async element => {
    //       const avatarUrl = await this.getImg2(element.lastname, element.firstname)
    //       return {
    //         element,
    //         avatarUrl,
    //       }
    //     })

    //     // Utilisez Promise.all pour attendre que toutes les promesses d'avatar soient résolues
    //     const avatarResults = await Promise.all(avatarPromises)

    //     avatarResults.forEach(({ element, avatarUrl }) => {
    //       moment(now).diff(moment(element.entry_date).format("YYYY-MM-DD"), "days") >= 6
    //         ? (element.career = true)
    //         : (element.career = false)

    //       element.current_month_costs.forEach(cost => {
    //         cost.documents.length === 0 ? (cost.documents = []) : (cost.documents = JSON.parse(cost.documents))
    //         cost.data != null ? (cost.data = JSON.parse(cost.data)) : (cost.data = null)

    //         if (moment(cost.date).format("MM") === month) {
    //           this.costAmountOfTheMonth += parseInt(cost.price)
    //         }

    //         if (moment(cost.date).format("YYYY") === year) {
    //           this.costAmountOfTheYear += parseInt(cost.price)
    //         }
    //       })

    //       if (parseInt(element.disabled) === 0) {
    //         this.users.push({
    //           id: element.id,
    //           firstname: element.firstname,
    //           lastname: element.lastname,
    //           lastConnection: moment(element.connection_date).calendar(), // Aujourd’hui à 11:46
    //           lastIPConnected: element.last_ip,
    //           statut: element.grade,
    //           email: element.email,
    //           phoneNumber: element.phone_number,
    //           completeName: `${element.lastname} ${element.firstname}`,
    //           agency: element.agency,
    //           // color: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
    //           color: "#001F47",
    //           initial: element.firstname[0] + element.lastname[0],
    //           dbl: parseInt(element.disabled),
    //           services: element.services,
    //           contract: element.contract,
    //           serviceChoose: element.services.length > 0 ? element.services[0].id_service_manager : 0,
    //           idTypeContract: element.contract.length > 0 ? element.contract[0].contract_type_id : 0,
    //           idTypeEmployment: element.contract.length > 0 ? element.contract[0].employment_type_id : 0,
    //           idTypeJob: element.contract.length > 0 ? element.contract[0].job_type_id : 0,
    //           idTypeQualification: element.contract.length > 0 ? element.contract[0].qualification_type_id : 0,
    //           street: element.street,
    //           city: element.city,
    //           zip: element.zip,
    //           costs: element.current_month_costs,
    //           avatarUrl: avatarUrl,
    //           entryDate: element.entry_date,
    //           entryDateDisplay: moment(element.entry_date).format("Do MMMM YYYY"),
    //           idLeaveReason: parseInt(element.id_leave_reason),
    //           leaveReason: element.leave_reason,
    //           endAgreementApprovalDate: element.end_agreement_approval_date,
    //           endAgreementApplicationDate: element.end_agreement_application_date,
    //           endDate: element.end_date,
    //           endDateDisplay: moment(element.end_date).format("Do MMMM YYYY"),
    //           familyMembers: element.family,
    //           childCount: element.child,
    //           marital_category: element.marital_category,
    //           idMaritalStatus: element.id_marital_category,
    //           idService: element.services.length > 0 ? element.services[0].id_service_manager : null,
    //           training: element.training,
    //           maintenance:
    //             element.maintenance.information != undefined ? JSON.parse(element.maintenance.information) : [],
    //         })
    //       } else {
    //         this.usersDisabled.push({
    //           id: element.id,
    //           firstname: element.firstname,
    //           lastname: element.lastname,
    //           lastConnection: moment(element.connection_date).calendar(), // Aujourd’hui à 11:46
    //           lastIPConnected: element.last_ip,
    //           statut: element.grade,
    //           email: element.email,
    //           phoneNumber: element.phone_number,
    //           completeName: `${element.lastname} ${element.firstname}`,
    //           agency: element.agency,
    //           // color: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
    //           color: "#001F47",
    //           initial: element.firstname[0] + element.lastname[0],
    //           dbl: parseInt(element.disabled),
    //           services: element.services,
    //           contract: element.contract,
    //           serviceChoose: element.services.length > 0 ? element.services[0].id_service_manager : 0,
    //           idTypeContract: element.contract.length > 0 ? element.contract[0].contract_type_id : 0,
    //           idTypeEmployment: element.contract.length > 0 ? element.contract[0].employment_type_id : 0,
    //           idTypeJob: element.contract.length > 0 ? element.contract[0].job_type_id : 0,
    //           idTypeQualification: element.contract.length > 0 ? element.contract[0].qualification_type_id : 0,
    //           street: element.street,
    //           city: element.city,
    //           zip: element.zip,
    //           costs: element.current_month_costs,
    //           avatarUrl: avatarUrl,
    //           entryDate: element.entry_date,
    //           entryDateDisplay: moment(element.entry_date).format("Do MMMM YYYY"),
    //           idLeaveReason: parseInt(element.id_leave_reason),
    //           leaveReason: element.leave_reason,
    //           endAgreementApprovalDate: element.end_agreement_approval_date,
    //           endAgreementApplicationDate: element.end_agreement_application_date,
    //           endDate: element.end_date,
    //           endDateDisplay: moment(element.end_date).format("Do MMMM YYYY"),
    //           familyMembers: element.family,
    //           childCount: element.child,
    //           marital_category: element.marital_category,
    //           idMaritalStatus: element.id_marital_category,
    //           idService: element.services.length > 0 ? element.services[0].id_service_manager : null,
    //           training: element.training,
    //           maintenance:
    //             element.maintenance.information != undefined ? JSON.parse(element.maintenance.information) : [],
    //         })
    //       }
    //     })
    //     // this.fetchFileCategories()

    //     this.open = [1]
    //     this.loading = false
    //     // this.fetch = tru        this.loading = false
    //     // this.waitTimeout = false

    //     // this.fetchServices()

    //     // await pause(3000)

    //     // this.loadedFiles = true
    //     // this.loadingFiles = false
    //     // this.fetchCostByUserId()
    //   } catch (e) {
    //     console.log(e)
    //     this.loading = false
    //     this.fetch = false
    //     this.$toast.error("Impossible d'accéder aux données de l'équipe.\nMerci de réessayer ultérieurement.", {
    //       position: "bottom-right",
    //       timeout: 3000,
    //     })
    //   }
    // },

    getLength() {
      return this.users.length
    },
    initialize() {
      this.users = []
      this.usersDisabled = []
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm(id) {
      this.users.splice(this.editedIndex, 1)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        fetch(`${config.apiUri}/accounts/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })
          .then(res => res.json()) // or res.json()
          .then(() => {
            this.$toast.info(`Le compte a correctement été supprimé.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      this.closeDelete()
      this.initialize()

      this.fetchUsers()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    sendmail(data) {
      try {
        emailjs.send("service_5p49t7p", "template_w0zk5rq", {
          to_name: data.lastname + " " + data.firstname,
          //from_name: email,
          to_email: data.email,
        })
      } catch (error) {
        console.log(error)
      }
    },

    save(idUser) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem)
        this.put(idUser, {
          lastname: this.editedItem.lastname,
          firstname: this.editedItem.firstname,
          grade: this.editedItem.statut,
          email: this.editedItem.email,
          phoneNumber: this.editedItem.phoneNumber,
          idServiceManager: this.editedItem.serviceChoose,
          idTypeContract: this.editedItem.idTypeContract,
          idTypeJob: this.editedItem.idTypeJob,
          idTypeEmployment: this.editedItem.idTypeEmployment,
          idTypeQualification: this.editedItem.idTypeQualification,
          familyMembers: this.editedItem.familyMembers,
          entryDate: this.editedItem.entryDate,
          idService: this.editedItem.idService,
          street: this.editedItem.street,
          city: this.editedItem.city,
          zip: this.editedItem.zip,
          idMaritalStatus: this.editedItem.idMaritalStatus,
        })
      } else {
        this.users.push(this.editedItem)
        this.post({
          lastname: this.editedItem.lastname,
          firstname: this.editedItem.firstname,
          email: this.editedItem.email,
          phoneNumber: this.editedItem.phoneNumber,
          idMasterAccount:
            localStorage.getItem("linked") != null
              ? localStorage.getItem("master_account_linked")
              : localStorage.getItem("user_id"),
          password: this.password,
          idServiceManager: this.editedItem.serviceChoose,
          idTypeContract: this.editedItem.idTypeContract,
          idTypeJob: this.editedItem.idTypeJob,
          idTypeEmployment: this.editedItem.idTypeEmployment,
          idTypeQualification: this.editedItem.idTypeQualification,
          familyMembers: this.editedItem.familyMembers,
          entryDate: this.editedItem.entryDate,
          idService: this.editedItem.idService,
          street: this.editedItem.street,
          city: this.editedItem.city,
          zip: this.editedItem.zip,
          idMaritalStatus: this.editedItem.idMaritalStatus,
        })
      }
      this.close()
    },
  },
}
</script>
<style lang="scss">
.fa-icone {
  font-size: 1.2rem;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  // position icon in middle of image
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.drop-zone {
  /* styles de base */
}

.drop-zone:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone-error {
  /* styles de base */
}

.drop-zone-error:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #c1272d;
}

.drop-zone-error.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

// if media screen is more than 1250px width then apply the following styles
@media screen and (min-width: 2000px) {
  .profilBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
  }
}

@media screen and (min-width: 1500px) {
  .profilBadge {
    position: absolute;
    top: 45px;
    z-index: 0;
    width: 0%;
  }
}

.profilBanner {
  // background-image: url("../../../../src/assets/images/profils/agence.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}

// hide button in list
.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item-explain {
  -webkit-line-clamp: 4 !important;
}

//§ todo

.theme--light.v-sheet h1,
.theme--light.v-sheet h2,
.theme--light.v-sheet h3,
.theme--light.v-sheet h4,
.theme--light.v-sheet h5,
.theme--light.v-sheet h6 {
  color: #001f47 !important;
}
.edit-dialog {
  max-width: 100px;
}

.no-padding-x {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
